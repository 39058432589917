import * as BABYLON from '@babylonjs/core';

export class ObscureInCenterPluginMaterial extends BABYLON.MaterialPluginBase {
  private _uniformCenter = new BABYLON.Vector4(0, 0.5, 0, 1);
  private _isEnabled = false;

  get isEnabled() {
    return this._isEnabled;
  }

  set isEnabled(enabled) {
    if (this._isEnabled === enabled) {
      return;
    }
    this._isEnabled = enabled;
    this.markAllDefinesAsDirty();
    this._enable(this._isEnabled);
  }

  set uniformCenter(center: BABYLON.Vector4) {
    if (this._uniformCenter === center) {
      return;
    }
    this._uniformCenter = center;
  }

  constructor(material: BABYLON.Material) {
    super(material, "ObscureInCenter", 200, { ObscureInCenter: false });
  }

  prepareDefines(defines: {[key: string]: boolean}, scene: BABYLON.Scene, mesh: BABYLON.Mesh) {
    defines['ObscureInCenter'] = this._isEnabled;
  }

  getUniforms() {
    return {
      ubo: [{ name: "obscureCenter", size: 4, type: "vec4" }],
      fragment: `#ifdef ObscureInCenter
                    uniform vec4 obscureCenter;
                #endif`,
    };
  }

  bindForSubMesh(uniformBuffer: BABYLON.UniformBuffer, scene: BABYLON.Scene, engine: BABYLON.Engine, subMesh: BABYLON.SubMesh) {
    if (this._isEnabled) {
      uniformBuffer.updateVector4("obscureCenter", this._uniformCenter);
    }
  }

  getClassName() {
    return "ObscureInCenterPluginMaterial";
  }

  getCustomCode(shaderType: "vertex" | "fragment") {
    return shaderType === "vertex"
      ? null
      : 
      {
        CUSTOM_FRAGMENT_MAIN_END: `
              #ifdef ObscureInCenter
                float obscureLength = length(vPositionW.xz - obscureCenter.xz);
                glFragColor.r = min(glFragColor.r, max(0.0125, glFragColor.r * obscureLength / obscureCenter.w));
                glFragColor.g = min(glFragColor.g, max(0.0125, glFragColor.g * obscureLength / obscureCenter.w));
                glFragColor.b = min(glFragColor.b, max(0.0125, glFragColor.b * obscureLength / obscureCenter.w));
              #endif
          `,
      };
  }
}
import { Pages } from "constants/slide-names";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { CanvasAppContext } from "service/CanvasContext";
import { GlobalAppContext } from "service/GlobalContext";
import { SceneEvent } from "service/SceneEvents";
import { SceneStage } from "service/StagesManager";
import "./InfoTabs.less";

export enum ALIGNMENT {
    RIGHT = "RIGHT",
    LEFT = "LEFT"
}


const stages: any = {
    [Pages.Greeting]: SceneStage.LOGO,
    [Pages.Approach]: SceneStage.FRACTAL,
    [Pages.Core]: SceneStage.GRAPH,
    [Pages.Team]: SceneStage.DNA,
    [Pages.Last]: SceneStage.LIGHTS_OFF,
    [Pages.Strategies]: SceneStage.COPULA,
    [Pages.Technology]: SceneStage.TERRAIN
}

export const InfoTabs: React.FC<any> = ({ children, onTabChange, position, pages = [], alignment = ALIGNMENT.LEFT, prevPosition }) => {
    const DELTA_SCROLL_FOR_SWICH_PAGE = 100;

    const [deltaScroll, setDeltaScroll] = useState(0);
    const [toushY, setToushY] = useState(0);
    const { sceneService } = useContext(CanvasAppContext);
    const {
        soundService: {
            transitionEffect,
            transitionEffect2,
            transitionEffect3,
            transitionEffect4,
            interactionEffect,
            transitionEffectLast,
            mainAudio
        }
    } = useContext(GlobalAppContext);

    useEffect(() => {
        setNewPage(position)
    }, [])

    useEffect(() => {
        document.addEventListener("wheel", onWheel);
        document.addEventListener('touchstart', onTouchStart);
        document.addEventListener('touchend', onTouchEnd);

        return () => {
            document.removeEventListener("wheel", onWheel);
            document.removeEventListener('touchstart', onTouchStart);
            document.removeEventListener('touchend', onTouchEnd);
        }
    }, [position, deltaScroll, toushY])

    const onClick = (page: Pages) => () => {
        setNewPage(page);
    }

    const onTouchStart = (e: TouchEvent) => {
        setToushY(e.touches[0].clientY);
    }

    const onTouchEnd = (e: TouchEvent) => {
        onWheel({ deltaY: toushY - e.changedTouches[0].clientY });
    }

    const setNewPage = (newPage: Pages) => {
        if (sceneService.isStageReady && sceneService.currentStage !== stages[newPage]) {
            sceneService.setStage(stages[newPage])
            onTabChange(newPage);
            playAudio(newPage)
        }
    }

    const playAudio = (newPage: Pages) => {
        if (newPage === Pages.Greeting) {
            transitionEffect.play();
            mainAudio.play();

            return
        }
        if (newPage === Pages.Approach) {
            transitionEffect2.play();
            mainAudio.play();

            return
        }
        if (newPage === Pages.Core || newPage === Pages.Strategies) {
            transitionEffect3.play();
            mainAudio.play();

            return
        }
        if (newPage === Pages.Team || newPage === Pages.Technology) {
            transitionEffect4.play();
            mainAudio.play();

            return
        }
        if (newPage === Pages.Last) {
            transitionEffectLast.play()
            mainAudio.pause();

            return
        }
    }

    const onWheel = (e: any) => {
        if (!sceneService.isStageReady) {
            return
        }

        setDeltaScroll(deltaScroll + e.deltaY)
        const index = pages.indexOf(position);

        if (deltaScroll + e.deltaY >= DELTA_SCROLL_FOR_SWICH_PAGE) {
            const page = pages[Math.min(pages.length - 1, index + 1)]
            setNewPage(page);
            setDeltaScroll(0)

        }

        if (deltaScroll + e.deltaY <= -DELTA_SCROLL_FOR_SWICH_PAGE) {
            const page = pages[Math.max(0, index - 1)]
            setNewPage(page);
            setDeltaScroll(0)
        }
    }

    const onMouseEnter = () => {
        interactionEffect.stop();
        interactionEffect.play();
    }

    return (
        <div className={`info-tabs ${alignment === ALIGNMENT.RIGHT ? "info-tabs__right" : ""}`}>
            <div className="info-tabs__pagination pagination">
                {pages.map((page: Pages, i: number) => (
                    <div onClick={onClick(page)} key={page} onMouseEnter={onMouseEnter}
                        className={`pagination__line pagination__line--dotted ${position === page ? 'pagination__line--active' : ''} ${prevPosition === page ? 'pagination__line--prev' : ''}`}
                    >
                        <span>00{++i}</span>
                    </div>
                ))}
            </div>
            {children}
        </div>
    );
}

import { teamMembers } from "constants/team";
import gsap from "gsap";
import { StackedCarousel, ResponsiveContainer, StackedCarouselSlideProps } from 'react-stacked-center-carousel';
import React, { useContext, useEffect, useRef, useState, useLayoutEffect } from "react";
import "./TeamSlider.less"
import { GlobalAppContext } from "service/GlobalContext";

export const TeamSlider: React.FC = () => {
    const containerRef = useRef({ clientWidth: 0 } as HTMLDivElement)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [slideWidth, setSlideWidth] = useState(0);

    useEffect(() => {
        const onResize = () => {
            setWindowWidth(window.innerWidth)
        }
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, [])

    useEffect(() => {
        setSlideWidth(windowWidth < 748 ?
            Math.max((containerRef.current.clientWidth + 120) / 3.7, 104) :
            Math.max(containerRef.current.clientWidth / 3.4, 104))
    }, [windowWidth])

    useLayoutEffect(() => {
        gsap.fromTo('.team-slider',
            {
                y: -200,
                opacity: 0,
            },
            {
                y: 0,
                opacity: 1,
                ease: "strong.inOut",
                delay: .7,
                duration: .7,
            }
        )

    }, [])

    return <div ref={containerRef} className="team-slider">
        <ResponsiveContainer
            render={(width, carouselRef) => {
                return (
                    <>
                        <StackedCarousel
                            ref={carouselRef}
                            slideComponent={Slide}
                            slideWidth={slideWidth}
                            carouselWidth={width}
                            data={teamMembers}
                            maxVisibleSlide={5}
                            disableSwipe
                            customScales={[1, 0.53, 0.53, 0.53]}
                            transitionTime={500}
                            fadeDistance={0}
                        />
                    </>
                );
            }}
        />
    </div>

};

const Slide = React.memo(function (props: StackedCarouselSlideProps) {
    const { data, dataIndex, isCenterSlide, swipeTo, slideIndex } = props;
    const [loadDelay, setLoadDelay] = React.useState<any>();
    const [removeDelay, setRemoveDelay] = React.useState<any>();
    const [loaded, setLoaded] = React.useState(false);
    const { soundService: { interactionEffect } } = useContext(GlobalAppContext);

    useEffect(() => {
        if (isCenterSlide) {
            clearTimeout(removeDelay);
            setLoadDelay(setTimeout(() => setLoaded(true), 1000));
        } else {
            clearTimeout(loadDelay);
            if (loaded) setRemoveDelay(setTimeout(() => setLoaded(false), 1000));
        }
    }, [isCenterSlide]);

    useEffect(() => () => {
        clearTimeout(removeDelay);
        clearTimeout(loadDelay);
    });

    const onMouseEnter = () => {
        interactionEffect.play();
    }

    const { src, name, position, social } = data[dataIndex];

    return (
        <div
            className="team-slider__slide"
            onClick={() => {
                if (!isCenterSlide) {
                    swipeTo(slideIndex);
                    interactionEffect.play();
                }
            }}
        >
            <img className="team-slider__member" src={src} alt={name} />
            <div className="member">
                <div className="member__info">
                    <span className="member__name paragraph-2">
                        {name}
                    </span>
                    <span className="member__position">
                        {position}
                    </span>
                </div>
                <div className="member__social">
                    {social.linkedin !== '/' && <a href={social.linkedin} target='_blank' onMouseEnter={onMouseEnter}>
                        <img src="assets/images/linkedin.svg" alt="linkedin"/>
                    </a>}
                </div>
            </div>
        </div>
    );
});

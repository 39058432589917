import { Button } from "components/shared/Button";
import React from "react";
import "./Loader.less";

export const Loader: React.FC<{ progress: number, onContinueClick: () => void }> = ({ progress, onContinueClick }) => {

    return (
        <div className="loader" onClick={onContinueClick} >
            <ul className="loader__animated-img">
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
                <li className="loader__particle"></li>
            </ul>
            <span className="loader__progress">{progress}%</span>
            {/* {progress === 100 && <Button>click to continue</Button>} */}
        </div>
    );
}

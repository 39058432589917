import React, { useContext } from "react";
import { GlobalAppContext } from "service/GlobalContext";

export const Button: React.FC<{ className?: string, onClick?: () => void, children: any }> = ({ onClick = () => { }, className = '', children }) => {
    // const { soundService: { buttonHover, buttonUnhover } } = useContext(GlobalAppContext);

    // const onMouseEnter = () => {
    //     buttonUnhover.stop();
    //     buttonHover.play()
    // }
    //
    // const onMouseLeave = () => {
    //     buttonHover.stop();
    //     buttonUnhover.play()
    // }

    return (
        <button onClick={onClick} className={`button ${className}`}>
            {children}
        </button>
    )
}

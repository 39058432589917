import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import gsap from "gsap";
import "./Accordion.less"
import { GlobalAppContext } from "service/GlobalContext";

interface IProps {
    children: React.FC,
    className?: string
}
export const Accordion: React.FC<IProps> = ({ children, className = '' }) => {
    const [openedTab, setOpenedTab] = useState(undefined);

    return (
        <div className={`accordion ${className}`}>
            {children({ openedTab, setOpenedTab })}
        </div>
    )
}

interface IItemProps {
    active: boolean;
    onToggle: () => void;
    header: string;
    message: string;
}

export const AccordionItem: React.FC<IItemProps> = ({ active, onToggle, header, message }) => {
    const ref = useRef();
    const { soundService: { interactionEffect, clickNavigation } } = useContext(GlobalAppContext)

    const onMouseEnter = () => {
        interactionEffect.play()
    }

    const onClick = () => {
        onToggle();
        clickNavigation.play();
    }

    useLayoutEffect(() => {
        let timeline: any
        timeline?.kill();
        if (active) {
            timeline = gsap.fromTo(ref.current,
                {
                    opacity: 0,
                    height: 0,
                },
                {
                    opacity: 1,
                    height: 'auto',
                    duration: .5,
                    ease: "strong.inOut",
                }
            )
        } else {
            timeline = gsap.fromTo(ref.current,
                {
                    opacity: 1,
                },
                {
                    opacity: 0,
                    height: 0,
                    duration: .5,
                    ease: "strong.inOut",
                }
            )
        }

        return () => { timeline?.kill() };
    }, [active])
    return (
        <div onClick={onClick} className={`accordion__item ${active ? "accordion__item--active" : ""}`}>
            <div onMouseEnter={onMouseEnter} className="accordion__toggle-img" />
            <div className="accordion__text">
                <h2 className="accordion__header subtitle-2">
                    {header}
                </h2>
                <div className="accordion__body" ref={ref}>
                    {message}
                </div>
            </div>
        </div>
    )
}
import React, { Component, MouseEvent } from "react";
import { configure } from "mobx"
import { observer } from "mobx-react";
import { SoundService } from "service/SoundService";
import { CanvasContext, CanvasAppContext } from "service/CanvasContext"
import "./styles/common.less";
import { Header } from "components/Header/Header";
import { Routes, Route, useLocation, Location } from "react-router-dom";
import { Main } from "components/Main/Main";
import { Loader } from "components/Loader/Loader";
import SceneCanvas from "components/shared/SceneCanvas";
import { Tech } from "components/Tech/Tech";
import { SceneStage } from "service/StagesManager";
import { SceneEvent } from "service/SceneEvents";
import { GlobalAppContext } from "service/GlobalContext";
import { ROUTES } from "constants/routes";
import { SoundButton } from "components/shared/SoundButton/SoundButton";
import { TeamContainer } from "components/Team/TeamContainer";
import { ContactContainer } from "components/Contact/ContactContainer";
import { FIRST_LOADED_KEY } from "constants/state";
import { RotateDisplay } from "components/RotateDisplay/RotateDisplay";

configure({
    enforceActions: "never",
});

/**
 * Main App container.
 *
 * @returns {JSX.Element}
 * @constructor
 */

@observer
class App extends Component<{ location: Location }> {

    state = {
        isSceneLoaded: false,
        isAssetsLoaded: false,
        isContinueClicked: false,
        isAudioAvailable: false,
        progress: 0,
        safeToRemove: false,
    }

    private soundService = new SoundService();

    componentDidUpdate(prevProps: Readonly<{ location: Location }>, prevState: Readonly<{ isAudioAvailable: boolean }>) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            if (this.props.location.pathname === ROUTES.Main) {
                if (this.appContext.sceneService.currentStage === SceneStage.LIGHTS_OFF || this.appContext.sceneService.currentStage === SceneStage.MEETUS) {
                    this.soundService.fromSecondToMain.play()
                    this.soundService.mainAudio.play();
                }
                this.appContext.sceneService.setStage(SceneStage.LOGO)
            }
            if (this.props.location.pathname === ROUTES.Tech) {
                if (this.appContext.sceneService.currentStage === SceneStage.LIGHTS_OFF || this.appContext.sceneService.currentStage === SceneStage.MEETUS) {
                    this.soundService.mainAudio.play();
                }
                this.appContext.sceneService.setStage(SceneStage.COPULA)
            }

            if (this.props.location.pathname === ROUTES.Team || this.props.location.pathname === ROUTES.Contact)  {
                this.appContext.sceneService.setStage(SceneStage.MEETUS)
                this.soundService.mainAudio.pause();
                this.soundService.secondPageStart.play();
            }
        }
        if (prevState.isAudioAvailable !== this.state.isAudioAvailable) {
            this.setAppVolume();
        }

    }

    setSceneUnload() {
        this.setState({
            isSceneLoaded: false
        })
    }

    toggleAppAudio() {
        this.setState({
            isAudioAvailable: !this.state.isAudioAvailable
        })
    }

    setAppVolume() {
        if (this.state.isAudioAvailable) {
            this.soundService.unmute();
            return;
        }
        this.soundService.mute();

    }

    componentDidMount() {
        if (
            this.props.location.pathname === ROUTES.Contact || this.props.location.pathname === ROUTES.Team
        ) {
            this.setState({
                isSceneLoaded: true
            });
        }

        this.appContext.sceneService.consumeOnEvent(SceneEvent.COMPLETED, () => {
            if (!this.state.isSceneLoaded) {
                if (this.props.location.pathname === ROUTES.Main) {
                    this.soundService.transitionEffect.play()
                }

                if (this.props.location.pathname === ROUTES.Tech) {
                    this.soundService.transitionEffect3.play()
                }
            }
            this.setState({
                isSceneLoaded: true
            });
        })

        this.appContext.sceneService.consumeOnEvent(SceneEvent.FLICKER, () => {
            this.soundService.flickerAudio.stop();
            this.soundService.flickerAudio.play();
        })
        this.appContext.sceneService.consumeOnEvent(SceneEvent.FLICKER_OFF, () => {
            this.soundService.flickerAudio.stop();
        })

        this.appContext.sceneService.consumeOnEvent(SceneEvent.ASSETS_LOADED, (value) => {

            this.setState({
                progress: this.state.progress < value.percents ? value.percents : this.state.progress
            })

            if (value.percents === 100) {

                    this.setState({
                        isAssetsLoaded: true
                    },this.onContinueClick)
            }
        })
    }

    onMouseMove(e: MouseEvent) {
        this.appContext.sceneService.offsetCamera(e.clientX, e.clientY)
    }

    onContinueClick() {


        if (!this.state.isAssetsLoaded) {
            return
        }
        
        this.setState({
            isContinueClicked: true
        });

        this.soundService.mute()

        if (document.location.pathname === ROUTES.Tech || document.location.pathname === ROUTES.Main) {
            this.appContext.sceneService.skipLightOnce()
            this.soundService.introAudio.play()
        }

        if (document.location.pathname === ROUTES.Main) {
            this.appContext.sceneService.setStage(SceneStage.LOGO)
            this.soundService.mainAudio.play();
        }
        if (document.location.pathname === ROUTES.Tech) {
            this.appContext.sceneService.setStage(SceneStage.COPULA)
            this.soundService.mainAudio.play();
        }

        if (document.location.pathname === ROUTES.Team || document.location.pathname === ROUTES.Contact) {
            this.appContext.sceneService.setStage(SceneStage.MEETUS)
            this.soundService.secondPageStart.play();
        }

        this.saveFirstLoad();
    }

    saveFirstLoad() {
        localStorage.setItem(FIRST_LOADED_KEY, FIRST_LOADED_KEY)
        setTimeout(() => {this.setState({safeToRemove: true})}, 1000)
    }

    appContext: CanvasContext = new CanvasContext();

    render() {
        return (
            <div className="App" onMouseMove={this.onMouseMove.bind(this)}>
                <RotateDisplay />
                <CanvasAppContext.Provider value={this.appContext}>
                    <SceneCanvas />
                    <GlobalAppContext.Provider value={{ ...this.state, toggleAppAudio: this.toggleAppAudio.bind(this), soundService: this.soundService, setSceneUnload: this.setSceneUnload.bind(this) }}>
                        {
                            (!this.state.isContinueClicked || !this.state.safeToRemove) &&
                            <Loader progress={this.state.progress} onContinueClick={this.onContinueClick.bind(this)} />
                        }
                        {this.state.isAssetsLoaded && this.state.isSceneLoaded && this.state.isContinueClicked && 
                            <>
                                <Header />
                                <SoundButton className="footer-sound-effect" />
                            </>
                        }
                        <Routes>
                            <Route path={ROUTES.Main} element={<Main />} />
                            <Route path={ROUTES.Tech} element={<Tech />} />
                            <Route path={ROUTES.Team} element={<TeamContainer />} />
                            <Route path={ROUTES.Contact} element={<ContactContainer />} />
                        </Routes>

                    </GlobalAppContext.Provider>
                </CanvasAppContext.Provider>
            </div>
        );
    }
}

export default () => {
    const location = useLocation();
    return <App location={location} />
}

import * as BABYLON from '@babylonjs/core';

export class FlickeringPluginMaterial extends BABYLON.MaterialPluginBase {
  private _delta = 1;
  private _isEnabled = false;

  get isEnabled() {
    return this._isEnabled;
  }

  set isEnabled(enabled) {
    if (this._isEnabled === enabled) {
      return;
    }
    this._isEnabled = enabled;
    this.markAllDefinesAsDirty();
    this._enable(this._isEnabled);
  }

  set delta(delta: number) {
    if (this._delta === delta) {
      return;
    }
    this._delta = delta;
  }

  get delta() {
    return this._delta;
  }

  constructor(material: BABYLON.Material) {
    super(material, "Flickering", 200, { Flickering: false });
  }

  prepareDefines(defines: {[key: string]: boolean}, scene: BABYLON.Scene, mesh: BABYLON.Mesh) {
    defines['Flickering'] = this._isEnabled;
  }

  getUniforms() {
    return {
      ubo: [{ name: "delta", size: 1, type: "float" }],
      fragment: `#ifdef Flickering
                    uniform float delta;
                #endif`,
    };
  }

  bindForSubMesh(uniformBuffer: BABYLON.UniformBuffer, scene: BABYLON.Scene, engine: BABYLON.Engine, subMesh: BABYLON.SubMesh) {
    if (this._isEnabled) {
      uniformBuffer.updateFloat("delta", this._delta);
    }
  }

  getClassName() {
    return "FlickeringPluginMaterial";
  }

  getCustomCode(shaderType: "vertex" | "fragment") {
    return shaderType === "vertex"
      ? null
      : 
      {
        CUSTOM_FRAGMENT_MAIN_END: `
              #ifdef Flickering
                glFragColor.rgb = vec3(fract(vColor.r + sin(0.005 * delta)));
                glFragColor.a = glFragColor.r;
                if (glFragColor.a < 0.125) discard;
              #endif
          `,
      };
  }
}
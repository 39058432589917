import * as BABYLON from "@babylonjs/core";
import { SceneMode } from "./SceneService";
import {environment} from "framer/build/utils/environment";

export enum SceneStage {
    LIGHTS_OFF = 0,
    LIGHTS_ON,
    LOGO,
    FRACTAL,
    GRAPH,
    DNA,
    COPULA,
    TERRAIN,
    MEETUS,
    LENGTH
}

type Environment = {
    isLightOn: boolean;
    glowingCube: BABYLON.Mesh;
    facesCube: BABYLON.Mesh;
    cubeA: BABYLON.Mesh;
    cubeB: BABYLON.Mesh;
    cubeC: BABYLON.Mesh;
    cubeD: BABYLON.Mesh;
    cubePlanes: BABYLON.Mesh;
    cubePlates: BABYLON.Mesh;
    gradientLines: BABYLON.Mesh;
    ground: BABYLON.Mesh;
    terrainCircles: BABYLON.Mesh;
    terrainGround: BABYLON.Mesh;
    terrainSky: BABYLON.Mesh;
    terrainSpheres: BABYLON.TransformNode;
    lights_on: BABYLON.Mesh;
}

export class StagesManager {
    private meshes2beforestages: { [stage: number]: { [mesh: BABYLON.Mesh['name']]: { property: string, value: number }[] } } = {};
    private meshes2stages: { [stage: number]: { [mesh: BABYLON.Mesh['name']]: { property: string, value: number }[] } } = {};
    private meshes2afterstages: { [stage: number]: { [mesh: BABYLON.Mesh['name']]: { property: string, value: number }[] } } = {};

    private animationGroups: { [stage: number]: BABYLON.AnimationGroup } = {};
    private animationGroupsAfter: { [stage: number]: BABYLON.AnimationGroup } = {};

    private cameraStages: {
        [stage: number]: {
            positions: {
                [key: number]: BABYLON.Vector3
            };
            targets: {
                [key: number]: BABYLON.Vector3
            };
        }
    } = {};

    private currentStage: SceneStage = SceneStage.LIGHTS_OFF;
    private camera;
    private cameraIsMoveing = false;
    private canvas;
    private environment: Environment;
    private engine;
    private scene;
    private gizmo;
    private callback;
    private onStartCallbacks: { [stage: number]: () => void } = {};
    private onEndCallbacks: { [stage: number]: () => void } = {};

    constructor(canvas: HTMLCanvasElement, engine: BABYLON.Engine, scene: BABYLON.Scene, environment: Environment,  camera: BABYLON.ArcRotateCamera, callback: (stage: SceneStage) => void, initGizmo = false) {
        this.canvas = canvas;
        this.engine = engine;
        this.scene = scene;
        this.environment = environment;

        //TBD
        if (initGizmo) {
            this.gizmo = new BABYLON.GizmoManager(this.scene);
            this.gizmo.positionGizmoEnabled = true;
            this.gizmo.rotationGizmoEnabled = true;
            this.gizmo.scaleGizmoEnabled = true;
            this.gizmo.gizmos.positionGizmo.updateGizmoRotationToMatchAttachedMesh = false;
            this.gizmo.gizmos.rotationGizmo.updateGizmoRotationToMatchAttachedMesh = false;
            this.gizmo.gizmos.scaleGizmo.updateGizmoRotationToMatchAttachedMesh = false;
            this.gizmo.gizmos.positionGizmo.onDragEndObservable.add(() => {
                console.log(
                    this.gizmo.gizmos.positionGizmo.attachedMesh.name,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.position.x,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.position.y,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.position.z,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.rotation.x,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.rotation.y,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.rotation.z,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.scaling.x,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.scaling.y,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.scaling.z
                );
            });
            this.gizmo.gizmos.rotationGizmo.onDragEndObservable.add(() => {
                console.log(
                    this.gizmo.gizmos.positionGizmo.attachedMesh.name,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.position.x,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.position.y,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.position.z,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.rotation.x,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.rotation.y,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.rotation.z,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.scaling.x,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.scaling.y,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.scaling.z
                );
            });
            this.gizmo.gizmos.scaleGizmo.onDragEndObservable.add(() => {
                console.log(
                    this.gizmo.gizmos.positionGizmo.attachedMesh.name,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.position.x,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.position.y,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.position.z,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.rotation.x,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.rotation.y,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.rotation.z,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.scaling.x,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.scaling.y,
                    this.gizmo.gizmos.positionGizmo.attachedMesh.scaling.z
                );
            });
        }
        this.camera = camera;
        this.callback = callback;
    }

    public setStage(stage: SceneStage, mode = SceneMode.HORIZONTAL) {

        const prevStage = this.currentStage;
        this.currentStage = stage;
        this.cameraStages[this.currentStage] && this.moveCamera(mode, true);
        if (this.animationGroupsAfter[prevStage]) {
            this.animationGroupsAfter[prevStage].start(false);
        }
        if (this.animationGroups[this.currentStage]) {
            this.animationGroups[this.currentStage].onAnimationGroupEndObservable.addOnce(() => {
                this.onEndCallbacks[this.currentStage] && this.onEndCallbacks[this.currentStage]();
                this.callback(this.currentStage);
            });
            //TBD
            for (let mesh in this.meshes2stages[this.currentStage]) {
                const node = this.scene.getMeshByName(mesh);
                this.gizmo && this.gizmo.attachToMesh(node);
            }
            this.animationGroups[this.currentStage].start(false);
            this.animationGroups[this.currentStage].targetedAnimations.length === 0 && this.callback(this.currentStage);
            this.onStartCallbacks[this.currentStage] && this.onStartCallbacks[this.currentStage]();

        } else {
            this.onStartCallbacks[this.currentStage] && this.onStartCallbacks[this.currentStage]();
            this.onEndCallbacks[this.currentStage] && this.onEndCallbacks[this.currentStage]();
        }
    }

    public offsetCamera(x: number, y: number) {
        if (!this.cameraIsMoveing && this.canvas) {
            this.camera.alpha += x / this.canvas.width * Math.PI * 0.01;
            this.camera.beta += y / this.canvas.height * Math.PI * 0.01;
        }
    }

    public resetCamera(mode: SceneMode) {
        if (this.cameraIsMoveing) {
            return;
        }
        this.moveCamera(mode, false);
    }

    public compile() {
        for (let stage in this.meshes2stages) {
            let animationGroup = new BABYLON.AnimationGroup(stage, this.scene);
            let isNeeded = false;
            for (let name in this.meshes2stages[stage]) {
                const mesh = this.scene.getMeshByName(name);
                this.meshes2stages[stage][name].forEach((e, i) => {
                    if (this.meshes2stages[stage][name][i] !== this.meshes2beforestages[stage][name][i]) {
                        const animation = new BABYLON.Animation(
                            stage + name + e.property,
                            e.property,
                            25,
                            BABYLON.Animation.ANIMATIONTYPE_FLOAT,
                            BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
                        );
                        animation.setKeys([{ frame: 0, value: this.meshes2beforestages[stage][name][i].value }, { frame: 75, value: e.value }]);
                        animationGroup.addTargetedAnimation(animation, mesh);
                        isNeeded = true;
                    }
                })
            }
            if (isNeeded) {
                this.animationGroups[stage] = animationGroup.normalize();
            }

            animationGroup = new BABYLON.AnimationGroup(stage + '_after', this.scene);
            isNeeded = false;
            for (let name in this.meshes2stages[stage]) {
                const mesh = this.scene.getMeshByName(name);
                this.meshes2stages[stage][name].forEach((e, i) => {
                    if (this.meshes2afterstages[stage] && this.meshes2afterstages[stage][name] && this.meshes2stages[stage][name][i] !== this.meshes2afterstages[stage][name][i]) {
                        const animation = new BABYLON.Animation(
                            stage + name + e.property + '_after',
                            e.property,
                            25,
                            BABYLON.Animation.ANIMATIONTYPE_FLOAT,
                            BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
                        );
                        animation.setKeys([{ frame: 0, value: e.value }, { frame: 75, value: this.meshes2afterstages[stage][name][i].value }]);
                        animationGroup.addTargetedAnimation(animation, mesh);
                        isNeeded = true;
                    }
                });
            }
            if (isNeeded) {
                this.animationGroupsAfter[stage] = animationGroup.normalize();
            }
        }
    }

    public setCameraStage(stage: SceneStage, positions: {[key: number]: BABYLON.Vector3}, targets: {[key: number]: BABYLON.Vector3}) {
        this.cameraStages[stage] = {
            positions: positions,
            targets: targets,
        };
    }

    private moveCamera(mode: SceneMode, isStageChanged: boolean) {
        const position = this.camera.position.clone();
        if (isStageChanged) {
            this.environment.terrainSky.setParent(null);
            this.environment.terrainSpheres.setParent(null);
            this.environment.terrainCircles.setParent(null);
        }
        const newPosition = this.cameraStages[this.currentStage].positions[mode].clone();
        const target = this.camera.targetScreenOffset.clone();
        const newTarget = this.cameraStages[this.currentStage].targets[mode].clone();
        const animationGroup = new BABYLON.AnimationGroup("cameraAG", this.scene);
        let animation = new BABYLON.Animation(
            'cameraAG_pos',
            'position',
            25,   // This sets the rotation speed of the cube.
            BABYLON.Animation.ANIMATIONTYPE_VECTOR3,
            BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
        );
        if (!this.intersectsHitbox(position, newPosition)) {
            animation.setKeys([
                { frame: 0, value: position },
                { frame: 75, value: newPosition }
            ]);
        } else {
            animation.setKeys([
                { frame: 0, value: position },
                { frame: 25, value: position.applyRotationQuaternion(BABYLON.Quaternion.FromEulerAngles(0, Math.PI / 4, 0)) },
                { frame: 50, value: position.applyRotationQuaternion(BABYLON.Quaternion.FromEulerAngles(0, 3 * Math.PI / 4, 0)) },
                { frame: 75, value: newPosition }
            ]);
        }


        animationGroup.addTargetedAnimation(animation, this.camera);

        animation = new BABYLON.Animation(
            'cameraAG_target',
            'targetScreenOffset',
            25,  // This moves the cube the initial time on load
            BABYLON.Animation.ANIMATIONTYPE_VECTOR2,
            BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
        );
        animation.setKeys([
            { frame: 0, value: target },
            { frame: 75, value: new BABYLON.Vector2(newTarget.x, -newTarget.y) }
        ]);
        animationGroup.addTargetedAnimation(animation, this.camera);

        animation = new BABYLON.Animation(
            'cameraAG_radius',
            'radius',
            25, 
            BABYLON.Animation.ANIMATIONTYPE_FLOAT,
            BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
        );
        animation.setKeys([
            { frame: 0, value: this.camera.radius },
            { frame: 50, value: this.camera.radius * 1.0 },
            { frame: 75, value: 6 }
        ]);
        animationGroup.addTargetedAnimation(animation, this.camera);



        if (Math.abs(this.camera.targetScreenOffset.y) > 0.5) {
            // console.log(this.camera.beta)
            //
            // animation = new BABYLON.Animation(
            //     'cameraAG_beta',
            //     'beta',
            //     25,
            //     BABYLON.Animation.ANIMATIONTYPE_FLOAT,
            //     BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
            // );
            // animation.setKeys([
            //     {frame: 0, value: this.camera.beta},
            //     {frame: 25, value: 1.5}
            // ]);
            // animationGroup.addTargetedAnimation(animation, this.camera);
        }


        animationGroup.onAnimationGroupEndObservable.addOnce(() => {
            if (isStageChanged) {
                this.environment.terrainSky.setParent(this.camera);
                this.environment.terrainSpheres.setParent(this.camera);
                this.environment.terrainCircles.setParent(this.camera);
            }
            this.cameraIsMoveing = false;
            this.camera.lowerBetaLimit = Math.PI / 4;
            this.camera.upperBetaLimit = Math.PI / 1.75;
            animationGroup.dispose();

            const additionalAG = new BABYLON.AnimationGroup("cameraAG", this.scene);
            // if (Math.abs(this.camera.targetScreenOffset.x) > 0.5) {
            //     animation = new BABYLON.Animation(
            //         'cameraAG_alpha',
            //         'alpha',
            //         25,
            //         BABYLON.Animation.ANIMATIONTYPE_FLOAT,
            //         BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
            //     );
            //     animation.setKeys([
            //         { frame: 0, value: this.camera.alpha },
            //         { frame: 25, value: this.camera.alpha + (Math.sign(this.camera.targetScreenOffset.x) * 0.3) }
            //     ]);
            //     additionalAG.addTargetedAnimation(animation, this.camera);
            // }

            // if (Math.abs(this.camera.targetScreenOffset.y) > 0.5) {
            //     console.log('strange')
            //     animation = new BABYLON.Animation(
            //         'cameraAG_beta',
            //         'beta',
            //         25,
            //         BABYLON.Animation.ANIMATIONTYPE_FLOAT,
            //         BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
            //     );
            //     animation.setKeys([
            //         { frame: 0, value: this.camera.beta },
            //         { frame: 25, value: this.camera.beta + (Math.sign(this.camera.targetScreenOffset.x) * 0.25) }
            //     ]);
            //     additionalAG.addTargetedAnimation(animation, this.camera);
            // }

            additionalAG.normalize().start(false, 1.4);  // This sets the speed of ALL
        });
        animationGroup.normalize().start(false, 1.4);
        this.cameraIsMoveing = true;
    }

    private intersectsHitbox(start: BABYLON.Vector3, end: BABYLON.Vector3) {
        const hitbox = this.scene.getMeshByName('hitbox');
        const ray = new BABYLON.Ray(start.multiplyByFloats(1, 0, 1), (end.multiplyByFloats(1, 0, 1)).subtract(start.multiplyByFloats(1, 0, 1)));
        return ray.intersectsMesh(hitbox).hit;
    }

    public onStart(stage: SceneStage, func: () => void) {
        this.onStartCallbacks[stage] = func;
    }

    public onEnd(stage: SceneStage, func: () => void) {
        this.onEndCallbacks[stage] = func;
    }

    public setMeshWeightForStage(stage: SceneStage, mesh: BABYLON.Mesh, morph: string, before: number, on: number) {
        this.setMeshWeightForBeforestage(mesh, morph, before, stage);
        this.setMeshWeightForOnstage(mesh, morph, on, stage);
    }

    private setMeshWeightForBeforestage(mesh: BABYLON.Mesh, morph: string, value: number, stage: SceneStage) {
        if (!this.meshes2beforestages[stage]) {
            this.meshes2beforestages[stage] = {};
        }
        if (!this.meshes2beforestages[stage][mesh.name]) {
            this.meshes2beforestages[stage][mesh.name] = [];
        }

        this.meshes2beforestages[stage][mesh.name].forEach((e, i) => {
            e.property && this.meshes2beforestages[stage][mesh.name].splice(i);
        });
        this.meshes2beforestages[stage][mesh.name].push({ property: morph, value: value });
    }

    private setMeshWeightForOnstage(mesh: BABYLON.Mesh, morph: string, value: number, stage: SceneStage) {
        if (!this.meshes2stages[stage]) {
            this.meshes2stages[stage] = {};
        }
        if (!this.meshes2stages[stage][mesh.name]) {
            this.meshes2stages[stage][mesh.name] = [];
        }

        this.meshes2stages[stage][mesh.name].forEach((e, i) => {
            e.property && this.meshes2stages[stage][mesh.name].splice(i);
        });
        this.meshes2stages[stage][mesh.name].push({ property: morph, value: value });
    }

    public setMeshPositionForStage(stage: SceneStage, mesh: BABYLON.Mesh, before: BABYLON.Vector3, on: BABYLON.Vector3, after: BABYLON.Vector3) {
        mesh.position = before.clone();
        this.setMeshPositionForBeforestage(mesh, before, stage);
        this.setMeshPositionForOnstage(mesh, on, stage);
        this.setMeshPositionForAfterstage(mesh, after, stage);
    }

    private setMeshPositionForBeforestage(mesh: BABYLON.Mesh, position: BABYLON.Vector3, stage: SceneStage) {
        if (!this.meshes2beforestages[stage]) {
            this.meshes2beforestages[stage] = {};
        }
        if (!this.meshes2beforestages[stage][mesh.name]) {
            this.meshes2beforestages[stage][mesh.name] = [];
        }

        this.meshes2beforestages[stage][mesh.name].forEach((e, i) => {
            if (e.property === 'position.x' || e.property === 'position.y' || e.property === 'position.z') {
                this.meshes2beforestages[stage][mesh.name].splice(i);
            }
        });
        this.meshes2beforestages[stage][mesh.name].push(
            { property: 'position.x', value: position.x },
            { property: 'position.y', value: position.y },
            { property: 'position.z', value: position.z }
        );
    }

    private setMeshPositionForOnstage(mesh: BABYLON.Mesh, position: BABYLON.Vector3, stage: SceneStage) {
        if (!this.meshes2stages[stage]) {
            this.meshes2stages[stage] = {};
        }
        if (!this.meshes2stages[stage][mesh.name]) {
            this.meshes2stages[stage][mesh.name] = [];
        }

        this.meshes2stages[stage][mesh.name].forEach((e, i) => {
            if (e.property === 'position.x' || e.property === 'position.y' || e.property === 'position.z') {
                this.meshes2stages[stage][mesh.name].splice(i);
            }
        });
        this.meshes2stages[stage][mesh.name].push(
            { property: 'position.x', value: position.x },
            { property: 'position.y', value: position.y },
            { property: 'position.z', value: position.z }
        );
    }

    private setMeshPositionForAfterstage(mesh: BABYLON.Mesh, position: BABYLON.Vector3, stage: SceneStage) {
        if (!this.meshes2afterstages[stage]) {
            this.meshes2afterstages[stage] = {};
        }
        if (!this.meshes2afterstages[stage][mesh.name]) {
            this.meshes2afterstages[stage][mesh.name] = [];
        }

        this.meshes2afterstages[stage][mesh.name].forEach((e, i) => {
            if (e.property === 'position.x' || e.property === 'position.y' || e.property === 'position.z') {
                this.meshes2afterstages[stage][mesh.name].splice(i);
            }
        });
        this.meshes2afterstages[stage][mesh.name].push(
            { property: 'position.x', value: position.x },
            { property: 'position.y', value: position.y },
            { property: 'position.z', value: position.z }
        );
    }
}

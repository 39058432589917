import { Button } from "components/shared/Button";
import { Navigation } from "components/shared/Navigation/Navigation";
import { TeamSlider } from "components/TeamSlider/TeamSlider";
import React, { useContext, useEffect } from "react";
import { CanvasAppContext } from "service/CanvasContext";
import { GlobalAppContext } from "service/GlobalContext";
import { SceneStage } from "service/StagesManager";
import { Team } from "./Team";
import "./Team.less";

export const TeamContainer: React.FC = () => {
    const { sceneService } = useContext(CanvasAppContext);
    const { isAssetsLoaded, isSceneLoaded, isContinueClicked } = useContext(GlobalAppContext);

    useEffect(() => {
        document.body.classList.add('team');

        if (isAssetsLoaded && (sceneService?.currentStage !== SceneStage.MEETUS)) {
            sceneService?.setStage(SceneStage.MEETUS)
        }
        return () => {
            document.body.classList.remove('team');
        }
    }, [isAssetsLoaded])

    return isAssetsLoaded && isContinueClicked && (
        <Team />
    );
}

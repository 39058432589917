import * as BABYLON from '@babylonjs/core';

export class ShowAroundPluginMaterial extends BABYLON.MaterialPluginBase {
    private _uniformPointer = new BABYLON.Vector4(0, 0, 0, 128 + 32);
    private _isEnabled = false;
    private _isAlpha = false;

    get isEnabled() {
        return this._isEnabled;
    }

    set isEnabled(enabled) {
        if (this._isEnabled === enabled) {
            return;
        }
        this._isEnabled = enabled;
        this.markAllDefinesAsDirty();
        this._enable(this._isEnabled);
    }

    get isAlpha() {
        return this._isAlpha;
    }

    set isAlpha(alpha) {
        if (this._isAlpha === alpha) {
            return;
        }
        this._isAlpha = alpha;
    }

    set uniformPointer(pointer: BABYLON.Vector4) {
        if (this._uniformPointer === pointer) {
            return;
        }
        this._uniformPointer = pointer;
    }

    constructor(material: BABYLON.Material) {
        super(material, "ShowAround", 200, {ShowAround: false});
    }

    prepareDefines(defines: { [key: string]: boolean }, scene: BABYLON.Scene, mesh: BABYLON.Mesh) {
        defines['ShowAround'] = this._isEnabled;
    }

    getUniforms() {
        return {
            ubo: [{name: "uniformPointer", size: 4, type: "vec4"}],
            fragment: `#ifdef ShowAround
                    uniform vec4 uniformPointer;
                #endif`,
        };
    }

    bindForSubMesh(uniformBuffer: BABYLON.UniformBuffer, scene: BABYLON.Scene, engine: BABYLON.Engine, subMesh: BABYLON.SubMesh) {
        if (this._isEnabled) {
            uniformBuffer.updateVector4("uniformPointer", this._uniformPointer);
        }
    }

    getClassName() {
        return "ShowAroundPluginMaterial";
    }

    getCustomCode(shaderType: "vertex" | "fragment") {
        return shaderType === "vertex"
            ?
            undefined
            :
            this.isAlpha ?
                {
                    CUSTOM_FRAGMENT_MAIN_END: `
                float saCoef = min(1.0, max(0.0, 1.0 - length((gl_FragCoord.xy * 1.0) - (uniformPointer.xy * 1.0) / 2.0) / (uniformPointer.w / 4.0)));
                // glFragColor.rgb = mix(vec3(1.0), glFragColor.rgb, saCoef);
                glFragColor.w = saCoef;`
                }
                :
                {
                    CUSTOM_FRAGMENT_MAIN_END: `
              #ifdef ShowAround
                float saCoef = min(1.0, max(0.0, 1.0 - length(gl_FragCoord.xy - uniformPointer.xy) / uniformPointer.w));
                glFragColor.rgb = mix(vec3(1.0), glFragColor.rgb, saCoef);
              #endif
          `
                };
    }
}

import React, { useContext, useEffect } from "react";
import { CanvasAppContext } from "service/CanvasContext";
import { GlobalAppContext } from "service/GlobalContext";
import { SceneStage } from "service/StagesManager";
import { Contact } from "./Contact";
import "./Contact.less";

export const ContactContainer: React.FC = () => {
    const { sceneService } = useContext(CanvasAppContext);
    const { isAssetsLoaded, isContinueClicked } = useContext(GlobalAppContext);

    useEffect(() => {
        document.body.classList.add('contact');

        if (isAssetsLoaded && (sceneService?.currentStage !== SceneStage.MEETUS)) {
            sceneService?.setStage(SceneStage.MEETUS)
        }
        return () => {
            document.body.classList.remove('contact');
        }
    }, [isAssetsLoaded])



    return isAssetsLoaded && isContinueClicked && (
        <Contact />
    );
}

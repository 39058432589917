import { useNavigateByURL } from "hooks/navigate";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { GlobalAppContext } from "service/GlobalContext";
import "./Navigation.less";

export const Navigation: React.FC<{
    className?: string
}> = ({ className = "" }) => {
    const navigateByHref = useNavigateByURL();
    const { soundService: { interactionEffect } } = useContext(GlobalAppContext);

    const onMouseEnter = () => {
        interactionEffect.play();
    }

    return (
        <nav className={`navigation-container ${className}`}>
            <NavLink to="/" onMouseEnter={onMouseEnter} onClick={navigateByHref} className={({ isActive }) =>
                `navigation-container__link ${isActive ? "navigation-container__link--active" : ""}`
            }>
                Main
            </NavLink>
            <NavLink to="/tech" onMouseEnter={onMouseEnter} onClick={navigateByHref} className={({ isActive }) =>
                `navigation-container__link ${isActive ? "navigation-container__link--active" : ""}`
            }>
                Tech
            </NavLink>
            <NavLink to="/team" onMouseEnter={onMouseEnter} onClick={navigateByHref} className={({ isActive }) =>
                `navigation-container__link ${isActive ? "navigation-container__link--active" : ""}`
            }>
                MEET US
            </NavLink>
            <NavLink to="/contact" onMouseEnter={onMouseEnter} onClick={navigateByHref} className={({ isActive }) =>
                `navigation-container__link ${isActive ? "navigation-container__link--active" : ""}`
            }>
                Contact
            </NavLink>
        </nav>
    )
}

import React, { useLayoutEffect, useRef, useState, useEffect, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import gsap from "gsap";
import { Navigation } from "../shared/Navigation/Navigation"
import "./Header.less";
import { useNavigateByURL } from "hooks/navigate";
import { SoundButton } from "components/shared/SoundButton/SoundButton";
import { GlobalAppContext } from "service/GlobalContext";

export const Header: React.FC = () => {
    const ref = useRef();
    const [isMenuOpened, toggleMenu] = useState(false)
    const location = useLocation();
    const navigateByHref = useNavigateByURL()
    const { soundService: { interactionEffect } } = useContext(GlobalAppContext);

    const onMouseEnter = () => {
        interactionEffect.play();
    }

    useEffect(() => {
        toggleMenu(false)
    }, [location])

    useLayoutEffect(() => {
        const timeline = gsap.fromTo(ref.current,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                duration: 0.5,
                ease: "strong.inOut",
            }
        )

        return () => { timeline?.kill() };

    }, []);

    const onBurgerClick = () => toggleMenu(toggle => !toggle)
    return (
        <header className={`header ${isMenuOpened ? "header--opened" : ''}`}>
            <NavLink to="/" onClick={navigateByHref} className="header__logo" onMouseEnter={onMouseEnter} ref={ref}>
                <img src="assets/images/corvus_logo.png" alt="logo" />
            </NavLink>

            <div className="sound-effect">
                <SoundButton />
            </div>
            <div className="header__burger" onClick={onBurgerClick}>
                <span></span>
                <span></span>
                <span></span>
            </div>

            <Navigation />
        </header>
    );
}
